import { render, staticRenderFns } from "./FooterComponentPakistan.vue?vue&type=template&id=7d27fd10"
import script from "./FooterComponentPakistan.vue?vue&type=script&lang=js"
export * from "./FooterComponentPakistan.vue?vue&type=script&lang=js"
import style0 from "./FooterComponentPakistan.vue?vue&type=style&index=0&id=7d27fd10&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports