<template>
  <footer :class="$style['web-mb-footer']">
    <ContainerComponent>
      <div :class="$style['web-mb-footer__content']">
        <div :class="$style['web-mb-footer__row']">
          <div :class="$style['web-mb-footer__logo-wrap']">
            <ImgComponent
              :src="logoImg"
              alt="NOVA Logo"
              :img-style="{ width: '85px', height: 'auto' }"
            />
          </div>
          <nav :class="$style['web-mb-footer__nav']">
            <template v-for="route in currentRoutes">
              <div v-if="route.nav.footer.show" :key="route.name">
                <a :href="route.path" :class="route.nav.footer.class">{{
                  route.title
                }}</a>
              </div>
            </template>
            <DropdownMenu
              v-if="!isEmployees"
              :colored="true"
              :is-footer="true" />
            <div :class="$style['web-mb-footer__double-divider']">
              {{ isEmployees ? "|" : "||" }}
            </div>

            <a
              :class="$style['web-mb-footer__source-wrap']"
              :href="isEmployees ? '/' : '/employees'"
              target="_blank"
              >{{ isEmployees ? "For Employer" : "For Employees" }}
            </a>
          </nav>
        </div>
        <div
          :class="[
            $style['web-mb-footer__divider'],
            $style['web-mb-footer__divider-hidden'],
          ]"
        ></div>
        <div :class="$style['web-mb-footer__info']">
          <div :class="$style['web-mb-footer__info-links']">
            <div :class="$style['web-mb-footer__info-data']">
              <!-- todo pakistan: позже добавить актуальные страницы для Пакистана -->
              <!--              <a-->
              <!--                href="/terms-and-conditions"-->
              <!--                class="selenium-footer-docs-terms-and-conditions"-->
              <!--                >Terms and Conditions</a-->
              <!--              >-->
              <a
                href="/privacy-policy"
                class="selenium-footer-docs-privacy-policy"
                >Privacy Policy</a
              >
              <!--              <a-->
              <!--                href="/fair-practices-code"-->
              <!--                class="selenium-footer-docs-fair-practices-code"-->
              <!--                >Fair Practices Code</a-->
              <!--              >-->
            </div>
            <div :class="$style['web-mb-footer__social']">
              <!-- todo pakistan: добавить актуальные ссылки на соц. сети -->
              <a
                href="https://www.instagram.com/vivamoney.in/"
                target="_blank"
                class="selenium-footer-contacts-instagram"
              >
                <ImgComponent
                  :src="instagramIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/vivamoney"
                target="_blank"
                class="selenium-footer-contacts-linkedin"
              >
                <ImgComponent
                  :src="linkedinIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="linkedin"
                />
              </a>
              <a
                href="https://www.facebook.com/vivamoney"
                target="_blank"
                class="selenium-footer-contacts-facebook"
              >
                <ImgComponent
                  :src="facebookIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="facebook"
                />
              </a>
              <!-- todo pakistan -->
              <a
                href="#"
                target="_blank"
                class="selenium-footer-contacts-youtube"
              >
                <ImgComponent
                  :src="youtubeIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="youtube"
                />
              </a>
              <!-- todo pakistan -->
              <a href="#" class="selenium-footer-contacts-mail">
                <ImgComponent
                  :src="mailIcon"
                  :img-style="{ width: '24px', height: '24px' }"
                  alt="mail"
                />
              </a>
            </div>
          </div>
          <div :class="$style['web-mb-footer__divider']"></div>
          <!-- todo pakistan: добавить актуальную инфо -->
          <div :class="$style['web-mb-footer__info-about']">
            <div :class="$style['web-mb-footer__info-about-col-first']">
              <div>
                <div>NBFC Partner</div>
                <div :class="$style['web-mb-footer__info-links']">
                  <a href="https://fincfriends.com/" target="_blank"
                    >FincFriends Private Limited</a
                  >
                </div>
              </div>
              <div>
                <div>CIN: U36737286KA74834</div>
                <div>©2023 NOVA SOLUTION PVT LTD</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerComponent>
  </footer>
</template>

<script>
import ContainerComponent from "@/components/ui/ContainerComponent.vue";
import ImgComponent from "@/components/ui/ImgComponent.vue";
import DropdownMenu from "@/components/pakistan/ui/DropdownMenu.vue";

// images
import logoImg from "@/assets/images/pakistan/logo-img.svg";
import googlePlayImg from "@/assets/images/site/google-play-img.webp";
import appStoreImg from "@/assets/images/site/app-store-img.webp";
import qrCodeImg from "@/assets/images/site/mb-qr-code.png";

// icons
import instagramIcon from "@/assets/icons/social/instagram-icon.svg";
import linkedinIcon from "@/assets/icons/social/linkedin-icon.svg";
import facebookIcon from "@/assets/icons/social/facebook-icon.svg";
import twitterIcon from "@/assets/icons/social/twitter-icon.svg";
import youtubeIcon from "@/assets/icons/social/youtube-icon.svg";
import mailIcon from "@/assets/icons/social/mail-icon.svg";

import routes from "@/router/routes.pakistan";

export default {
  name: "FooterComponentPakistan",
  components: {
    ContainerComponent,
    ImgComponent,
    DropdownMenu,
  },
  data: () => {
    return {
      routes,
      logoImg,
      googlePlayImg,
      appStoreImg,
      qrCodeImg,
      instagramIcon,
      linkedinIcon,
      facebookIcon,
      twitterIcon,
      youtubeIcon,
      mailIcon,
    };
  },
  computed: {
    isEmployees() {
      return this.$route.path.includes("/employees");
    },
    // todo pakistan
    currentRoutes() {
      return this.isEmployees
        ? this.routes.filter((route) => route.nav.site === "employees")
        : this.routes.filter((route) => route.nav.site !== "employees"); // employers
    },
  },
};
</script>

<style lang="scss" module>
.text-right {
  text-align: center;
}
.web-mb-footer {
  margin-top: auto;
  background-color: #ffffff;
  padding: 4rem 1rem 1.5rem 1rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__divider {
    width: 100%;
    height: 0.8px;
    background: #96adb8;
    opacity: 0.5;
    display: none;

    &-hidden {
      display: block;
    }
  }

  &__double-divider {
    display: none;
    color: #96adb8;
    font-weight: 300;
  }

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__logo-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }

  &__mb-email {
    font-weight: 600;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    font-size: 18px; // ?
  }

  &__nav a:link,
  &__nav a:visited,
  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active {
    color: #043ad9;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.15s ease-in-out;
  }

  &__source-wrap {
    display: flex;

    &::after {
      width: 24px;
      height: 24px;
      content: url(../../../assets/icons/source-icon-2.svg);
      margin-top: 0.1rem;
      margin-left: 0.3rem;
    }
  }

  &__nav a:hover,
  &__nav a:focus,
  &__nav a:active {
    color: #0e1a22;
  }

  &__source-wrap:hover,
  &__source-wrap:focus,
  &__source-wrap:active {
    color: #0e1a22;
    &::after {
      content: url(../../../assets/icons/source-icon-1.svg);
    }
  }

  &__download-wrap {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__qr-code-img {
    display: none;
  }

  &__info {
    display: flex;
    flex-direction: column;
    font-size: 0.75em;
    color: #5a717c;

    a:link,
    a:visited,
    a:hover,
    a:focus,
    a:active {
      color: #5a717c;
    }
  }

  &__info-data {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    order: 2;
    justify-content: center;
  }

  &__social {
    display: flex;
    justify-content: center;
    order: 1;
    gap: 1.5rem;
  }

  &__info-links {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    a:link,
    a:visited,
    a:hover,
    a:focus,
    a:active {
      text-decoration: underline;
      transition: color 0.15s ease-in-out;
    }

    a:hover,
    a:focus,
    a:active {
      color: #043ad9;
    }
  }

  &__info-about {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;

    &-col {
      &-first {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }
    }
  }

  @media (min-width: $min-md) {
    padding: 3rem 2.75rem 1.5rem 2.75rem;

    &__double-divider {
      display: block;
    }

    &__divider {
      display: block;
      &-hidden {
        display: none;
      }
    }

    &__info {
      gap: 1.5rem;
    }

    &__row {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__logo-wrap {
      padding: 0;
    }

    &__mb-email {
      display: none;
    }
    &__nav {
      margin-top: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      gap: 1.5rem;
    }

    &__info-data {
      order: 1;
    }

    &__social {
      width: auto;
      gap: 2rem;
      order: 2;
    }

    &__info-about {
      text-align: initial;

      &-col {
        &-first {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  @media (min-width: $min-lg) {
    padding: 3.75rem 7rem 1.5rem 7rem;

    .text-right {
      text-align: right;
    }

    &__content {
      gap: 3rem;
    }

    &__qr-code-img {
      display: block;
    }

    &__info-data {
      flex-wrap: nowrap;
    }

    &__info-links {
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
    }

    &__info-about {
      flex-direction: row;
      justify-content: space-between;

      &-col {
        &-first {
          display: flex;
          flex: 2;
          gap: 2rem;
          justify-content: start;
        }

        &-second {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
</style>